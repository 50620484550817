import type { FC } from 'react';
import React from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { useOrderHistory } from '../provider';

interface Props {
    learnerAssignmentLink?: string;
}

const OrderActions: FC<Props> = ({ learnerAssignmentLink }) => {
    const { clearSelectedOrder } = useOrderHistory();
    const { customerSupportLink } = useBrandSettingsContext();
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

    return (
        <>
            <Button variant="secondary" size="s" className="rounded-md px-16 py-8" onClick={clearSelectedOrder}>
                <Typography className="text-center">{formatOrdersMessage({ id: 'button.back', defaultMessage: 'Back' })}</Typography>
            </Button>

            {customerSupportLink && (
                <Link link={customerSupportLink}>
                    <Button variant="secondary" size="s" className="rounded-md px-16 py-8 lg:mb-0">
                        <Typography className="text-center">
                            {formatOrdersMessage({ id: 'button.contact.support', defaultMessage: 'Contact Customer Support' })}
                        </Typography>
                    </Button>
                </Link>
            )}
            {learnerAssignmentLink && (
                <Link link={learnerAssignmentLink} data-cy="learner-assignments-link" target="_blank">
                    <Button variant="primary" size="s" className="rounded-md !bg-learner-accent px-16 py-8">
                        <Typography>{formatCommonMessage({ id: 'assign.learners', defaultMessage: 'Assign learners' })}</Typography>
                    </Button>
                </Link>
            )}
        </>
    );
};

export default OrderActions;
