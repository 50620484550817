import type { FC } from 'react';
import React from 'react';
import type { Order } from '@wilm/shared-types/cart';
import OrderActions from './order-actions';
import OrderInfoSection from './order-info';
import Typography from 'components/commercetools-ui/atoms/typography';
import OrderItem from 'components/commercetools-ui/organisms/order-items-listing/order-item';
import { useFormat } from 'helpers/hooks/useFormat';
import useBundle from 'frontastic/hooks/useBundle';
import Costs from 'components/commercetools-ui/organisms/order-payment-section/components/costs';

export interface Props {
    order: Order;
}

const OrderPage: FC<Props> = ({ order }) => {
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

    const { nonBundleItems, bundleComponents } = useBundle(order?.lineItems ?? []);

    const { totalItems } = useBundle(order.lineItems!);
    const itemsLabel =
        totalItems === 1
            ? formatOrdersMessage({
                  id: 'item',
                  values: { total: totalItems },
                  defaultMessage: '{total} item'
              })
            : formatOrdersMessage({
                  id: 'items',
                  values: { total: totalItems },
                  defaultMessage: '{total} items'
              });
    return (
        <div className="md:mt-20 lg:mt-40">
            <Typography
                as="h2"
                className="mt-20 px-16 text-primary-black md:mt-0 md:pl-24 md:text-22 lg:pl-30 lg:text-24"
                data-cy="order-details-title"
            >
                {formatOrdersMessage({
                    id: 'order.details',
                    defaultMessage: 'Order Details'
                })}
            </Typography>
            <div className="mx-16 mt-16 flex flex-col gap-16 sm:flex-row sm:gap-8 md:mx-24 lg:mx-30">
                <OrderActions learnerAssignmentLink={order?.learnerAssignmentLink} />
            </div>
            <div className="mt-30 flex px-16 md:px-24 lg:flex-col lg:px-30 xl:flex-row">
                <OrderInfoSection order={order} />

                <div className="ml-30 hidden h-fit w-[42%] rounded-md border lg:px-36 lg:py-20 2xl:block 3xl:w-[37%]">
                    <div className="pb-16">
                        <Typography className="mb-6 font-bold">{itemsLabel + ':'}</Typography>
                        {nonBundleItems?.map(lineItem => (
                            <OrderItem
                                key={lineItem.lineItemId}
                                lineItem={lineItem}
                                bundleItems={bundleComponents?.[lineItem.lineItemId]}
                                isLastItem={order?.lineItems?.indexOf(lineItem) === (order?.lineItems?.length ?? 0) - 1}
                            />
                        ))}
                    </div>
                    <Costs order={order} className="border-t" dataReference="order" />
                </div>
            </div>
        </div>
    );
};

export default OrderPage;
