import React from 'react';
import type { Order } from '@wilm/shared-types/cart/Order';
import { v4 as uuid } from 'uuid';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import OrderPage from '../order-page';
import OrderItem from '../OrderItem';
import { useOrderHistory } from '../provider';

const OrderList: React.FC = () => {
    const { fullOrderHistory, selectedOrder, loading, totalPages, currentPage, moveToPageHandler, selectOrder } = useOrderHistory();
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

    if (selectedOrder) {
        return <OrderPage order={selectedOrder} />;
    }

    return (
        <>
            <Typography
                as="h2"
                className="ml-15 mt-22 text-18 text-primary-black md:ml-24 md:text-22 lg:ml-44 lg:mt-42"
                data-cy="orders-page-title"
            >
                {formatOrdersMessage({
                    id: 'orders',
                    defaultMessage: 'Orders'
                })}
            </Typography>

            <div className="mb-5 mt-15 px-16 md:mt-20 md:px-24 lg:mb-18 lg:px-44">
                <Typography as="h3" className="text-md text-secondary-black md:text-16">
                    {formatOrdersMessage({
                        id: 'help.question',
                        defaultMessage: 'Check status of recent orders, manage your returns and download invoices.'
                    })}
                </Typography>
            </div>
            <div className="overflow-auto px-15 pt-15 md:px-24 lg:px-44">
                {loading
                    ? [...Array(3)].map(i => <OrderItem key={i} loading={loading} selectOrder={() => {}} />)
                    : fullOrderHistory?.orders?.map((order?: Order) => (
                          <OrderItem key={order?.orderId} order={order} selectOrder={selectOrder} />
                      ))}
            </div>

            {!loading && totalPages > 1 && (
                <div className="overflow-auto px-15 pt-15 md:px-24 lg:px-44">
                    {[...Array(totalPages)].map((_p, i) => {
                        if (i + 1 === currentPage) {
                            return (
                                <Button
                                    key={uuid()}
                                    className="ml-8 mt-20 cursor-default rounded-md px-16 py-8 hover:shadow-none hover:outline-none xl:mt-0"
                                    size="xs"
                                    data-cy="order-pagination-button"
                                    variant="secondary"
                                >
                                    {i + 1}
                                </Button>
                            );
                        } else {
                            return (
                                <Button
                                    key={uuid()}
                                    className="ml-8 mt-20 rounded-md px-16 py-8 xl:mt-0"
                                    size="xs"
                                    data-cy="order-pagination-button"
                                    variant="primary"
                                    onClick={() => moveToPageHandler(i + 1)}
                                >
                                    {i + 1}
                                </Button>
                            );
                        }
                    })}
                </div>
            )}
        </>
    );
};

export default OrderList;
