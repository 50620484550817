import React from 'react';
import OrderList from './list';
import OrderHistoryProvider from './provider';

interface OrdersProps {
    orderNumber?: string;
}

const Orders: React.FC<OrdersProps> = ({ orderNumber }) => {
    return (
        <OrderHistoryProvider orderNumber={orderNumber}>
            <OrderList />
        </OrderHistoryProvider>
    );
};

export default Orders;
