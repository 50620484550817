import { useCallback } from 'react';
import type { Address } from '@wilm/shared-types/account/Address';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from 'helpers/hooks/useI18n';
import { useAccount } from 'frontastic';

const usePropsToAddressType = () => {
    const { country } = useI18n();
    const { formatMessage } = useFormat({ name: 'checkout' });

    const { addBillingAddress, addShippingAddress, setDefaultBillingAddress, setDefaultShippingAddress, updateAddress } = useAccount();

    const getAddressType = useCallback((address: Address) => {
        if (address?.addressType) return address?.addressType;
        return address?.isBillingAddress ? 'billing' : 'shipping';
    }, []);

    const mapPropsToAddress = useCallback(
        (address: Address) => {
            const addressType = getAddressType(address);

            const typeBasedProps = {
                billing: {
                    checked: address?.isDefaultBillingAddress,
                    label: formatMessage({ id: 'billing', defaultMessage: 'Billing' }).toLowerCase(),
                    addressType: addressType,
                    setAsDefault: async () => {
                        if (address) {
                            await setDefaultBillingAddress(address.addressId!);
                        }
                    },
                    addAddress: async () => {
                        return await addBillingAddress({
                            country,
                            ...address,
                            isDefaultBillingAddress: address?.isDefault
                        });
                    },
                    updateAddress: async () => {
                        return await updateAddress({ ...address, isDefaultBillingAddress: address?.isDefault });
                    }
                },
                shipping: {
                    checked: address?.isDefaultShippingAddress,
                    label: formatMessage({ id: 'shipping', defaultMessage: 'Shipping' }).toLowerCase(),
                    addressType: addressType,

                    setAsDefault: async () => {
                        if (address) {
                            await setDefaultShippingAddress(address.addressId!);
                        }
                    },
                    addAddress: async () => {
                        return await addShippingAddress({
                            country,
                            ...address,
                            isDefaultShippingAddress: address?.isDefault
                        });
                    },
                    updateAddress: async () => {
                        return await updateAddress({ ...address, isDefaultShippingAddress: address?.isDefault });
                    }
                }
            };

            return typeBasedProps[addressType];
        },
        [country, getAddressType, addBillingAddress, addShippingAddress, setDefaultBillingAddress, setDefaultShippingAddress, updateAddress]
    );

    return { mapPropsToAddress };
};

export default usePropsToAddressType;
