import type { FC } from 'react';
import React from 'react';
import { useParams } from 'next/navigation';
import type { Order } from '@wilm/shared-types/cart/Order';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import OrderItemList from 'components/commercetools-ui/organisms/order-items-listing/order-item';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from 'helpers/hooks/useI18n';
import mapCosts from 'helpers/utils/mapCosts';
import useBundle from 'frontastic/hooks/useBundle';

interface Props {
    order?: Order;
    loading?: boolean;
    selectOrder: (params: { order?: Order; orderId?: string }) => void;
}

const OrderItem: FC<Props> = ({ order, loading, selectOrder }) => {
    const { currency } = useI18n();
    const { locale } = useParams();
    const { nonBundleItems, bundleComponents } = useBundle(order?.lineItems || []);
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    const { total: getTotal } = mapCosts({ order, currency });
    const orderDate = order?.createdAt && new Date(order?.createdAt).toISOString().split('T')[0].split('-');
    const formattedDate = orderDate && [orderDate[2], orderDate[1], orderDate[0]].join('/');

    if (loading) {
        return (
            <div className="mb-15 w-full border border-input-disabled">
                <div className="grid grid-cols-1 border-b-input-disabled bg-gray-100 px-28 py-20 text-md xl:grid-cols-[1fr_1fr_1fr_4fr]">
                    <div className="flex flex-col">
                        <Skeleton width={100} height={18} />
                        <Skeleton width={100} height={25} className="mt-8" />
                    </div>
                    <div className="flex flex-col">
                        <Skeleton width={100} height={18} />
                        <Skeleton width={100} height={25} className="mt-8" />
                    </div>
                    <div className="flex flex-col">
                        <Skeleton width={100} height={18} />
                        <Skeleton width={100} height={25} className="mt-8" />
                    </div>
                    <div className="flex flex-col items-end">
                        <Skeleton width={150} height={18} />
                        <Skeleton width={100} height={18} className="mt-10" />
                    </div>
                </div>
                <div className="grid grid-cols-1 items-center border-input-disabled px-28 py-20 text-md xl:grid-cols-[3fr_1fr_4fr]">
                    <div className="flex flex-col">
                        <Skeleton width={100} height={20} />
                        <Skeleton width={200} height={20} className="mt-8" />
                    </div>
                    <div className="flex flex-col">
                        <Skeleton width={100} height={20} />
                        <Skeleton width={200} height={20} className="mt-8" />
                    </div>
                    <div className="flex flex-col items-end">
                        <Skeleton width={100} height={20} />
                        <Skeleton width={200} height={20} className="mt-8" />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="mb-15 w-full border border-input-disabled">
            <div className="border border-x-0 border-t-0 bg-gray-100">
                <div className="grid grid-cols-1 gap-y-20 px-28 py-20 text-md sm:grid-cols-[1fr_1fr] xl:grid-cols-[1fr_1fr_1fr_4fr]">
                    {formattedDate ? (
                        <div className="">
                            <Typography
                                className="inline text-sm font-bold uppercase text-primary-gray sm:block"
                                data-cy="order-item-order-date-label"
                            >
                                {formatOrdersMessage({ id: 'order.created.date', defaultMessage: 'Created date:' })}
                            </Typography>
                            <Typography
                                className="ml-4 inline text-lg text-primary-dark-gray sm:ml-0 sm:block md:mt-6"
                                data-cy="order-item-order-date"
                            >
                                {formattedDate}
                            </Typography>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    {getTotal ? (
                        <div className="">
                            <Typography
                                className="inline text-sm font-bold uppercase text-primary-gray sm:block"
                                data-cy="order-item-order-total-label"
                            >
                                {formatOrdersMessage({ id: 'order.total', defaultMessage: 'Total:' })}
                            </Typography>
                            <Typography
                                className="ml-4 inline text-lg text-primary-dark-gray sm:ml-0 sm:block md:mt-6"
                                data-cy="order-item-order-total"
                            >
                                {CurrencyHelpers.formatForCurrency(getTotal, locale)}
                            </Typography>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    {order?.paymentState ? (
                        <div className="">
                            <Typography
                                className="inline text-sm font-bold uppercase text-primary-gray sm:block"
                                data-cy="order-item-order-status-label"
                            >
                                {formatOrdersMessage({ id: 'payment.status', defaultMessage: 'Payment Status:' })}
                            </Typography>
                            <Typography
                                className="ml-4 inline text-lg text-primary-dark-gray sm:ml-0 sm:block md:mt-6"
                                data-cy="order-item-order-status"
                            >
                                {order?.paymentState}
                            </Typography>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="xl:text-right">
                        {order?.orderId && (
                            <>
                                <p>
                                    <Typography
                                        className="inline text-sm font-bold uppercase text-primary-gray sm:inline-block xl:inline"
                                        data-cy="order-item-order-number-label"
                                    >
                                        {formatOrdersMessage({ id: 'order.number', defaultMessage: 'Order number:' })}
                                    </Typography>{' '}
                                    <Typography
                                        className="inline text-lg text-primary-dark-gray sm:inline-block xl:inline xl:text-md"
                                        data-cy="order-item-order-number"
                                    >
                                        {order.orderId}
                                    </Typography>
                                </p>
                                <Button
                                    variant="ghost"
                                    size="fit"
                                    className="mt-12 hidden pb-1 text-btn-lg-size text-learner-accent-1 underline hover:no-underline xl:inline-block"
                                    onClick={() => selectOrder({ orderId: order.orderId })}
                                    data-cy="order-item-details-button"
                                >
                                    <Typography data-cy="order-item-view-order-details">
                                        {formatOrdersMessage({ id: 'view.order.details', defaultMessage: 'View order details' })}
                                    </Typography>
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                {order?.learnerAssignmentLink && (
                    <div className="px-20 pb-10 xl:hidden">
                        <Link link={order?.learnerAssignmentLink} data-cy="learner-assignments-link-mobile" target="_blank">
                            <Button variant="primary" size="full" className="rounded-md !bg-learner-accent font-regular xl:mt-0">
                                <Typography>{formatCommonMessage({ id: 'assign.learners', defaultMessage: 'Assign learners' })}</Typography>
                            </Button>
                        </Link>
                    </div>
                )}
                {order?.orderId && (
                    <div className="px-20 pb-24 xl:hidden">
                        <Button
                            variant="secondary"
                            size="full"
                            className="mt-12 text-btn-lg-size"
                            onClick={() => selectOrder({ orderId: order.orderId })}
                            data-cy="order-item-details-button-mobile"
                        >
                            <Typography data-cy="order-item-view-order-details">
                                {formatOrdersMessage({ id: 'view.order.details', defaultMessage: 'View order details' })}
                            </Typography>
                        </Button>
                    </div>
                )}
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2">
                <div>
                    <div className="grid grid-cols-[4fr_1fr] items-center border-input-disabled px-28 pt-20 text-md">
                        <div className="">
                            <Typography className="font-bold" data-cy="order-item-label">
                                {formatOrdersMessage({ id: 'item.label', defaultMessage: 'Item:' })}
                            </Typography>
                        </div>
                        <div className="">
                            <Typography className="text-right font-bold" data-cy="order-item-label">
                                {formatOrdersMessage({ id: 'qty.label', defaultMessage: 'Qty:' })}
                            </Typography>
                        </div>
                    </div>
                    <div className="px-28 pb-20">
                        {nonBundleItems?.map(lineItem => (
                            <OrderItemList
                                key={lineItem.lineItemId}
                                lineItem={lineItem}
                                bundleItems={bundleComponents?.[lineItem.lineItemId]}
                                isLastItem={order?.lineItems?.indexOf(lineItem) === (order?.lineItems?.length ?? 0) - 1}
                                hideAttributes
                                showPrice={false}
                                containerClassName={'border-none'}
                            />
                        ))}
                    </div>
                </div>
                <div className="hidden pr-20 pt-20 text-end xl:block">
                    {order?.learnerAssignmentLink && (
                        <Link link={order?.learnerAssignmentLink} data-cy="learner-assignments-link" target="_blank">
                            <Button variant="primary" size="s" className="mt-20 rounded-md !bg-learner-accent px-16 py-8 xl:mt-0">
                                <Typography>{formatCommonMessage({ id: 'assign.learners', defaultMessage: 'Assign learners' })}</Typography>
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OrderItem;
